<script setup lang="ts">
import {PropType, ref} from 'vue';
import {Link, useForm} from '@inertiajs/vue3';
import Layout from '@/Layouts/Default.vue';
import LinkComponent from '@/Shared/Forms/LinkComponent.vue';
import ConfirmationDialog from '@/Shared/ConfirmationDialog.vue';
import ButtonComponent from '@/Shared/Forms/ButtonComponent.vue';
import AppHead from '@/Shared/AppHead.vue';
import {useMixin} from "@/mixins";

const {route, __, __choice} = useMixin();

const props = defineProps({
    user: {
        type: Object as PropType<App.Models.User>,
        required: true,
    },
    cards: {
        type: Array as PropType<Array<App.Models.Card>>,
        default: [],
    },
    sendConversionOnce: {
        type: Boolean,
        required: true,
    }
});

const form = useForm({});
const deleteDialogRefs = ref<InstanceType<typeof ConfirmationDialog>[]>([]);
const duplicateDialogRefs = ref<InstanceType<typeof ConfirmationDialog>[]>([]);

const openDeleteModal = (index: number) => {
    deleteDialogRefs.value[index].open();
};

const openDuplicateModal = (index: number) => {
    duplicateDialogRefs.value[index].open();
};

const resendVerificationMail = () => {
    form.post(route('app.verification.resend'));
};

window.addEventListener("load", function () {
    progressier.add({
        id: props.user.id,
        email: props.user.email,
    });
});

if (props.sendConversionOnce && localStorage.getItem('event.signup') !== 'true') {
    localStorage.setItem('event.signup', 'true');
    // @ts-ignore
    if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'signup',
        });
    }
}
</script>

<template>
    <AppHead title="Dashboard"/>
    <layout class="bg-flower-50" :disable-spacer="true">

        <div class="md:w-[725px] md:mx-auto">
            <div class="bg-white border-2 border-flower-300 rounded-lg p-3 shadow-md mb-3"
                 v-if="!user.has_verified_email">

                <p class="text-base text-flower-700">
                    ⚠️&nbsp;&nbsp;Bitte bestätigt noch Eure E-Mail-Adresse, um den vollen Funktionsumfang von <strong>Mi
                    Boda</strong> nutzen zu können. Schaut einfach in Eurem E-Mail-Postfach nach.
                </p>

                <span class="text-base text-flower-700">
                    Klicke <strong @click="resendVerificationMail">hier</strong>, um die E-Mail erneut zu versenden.
                </span>
            </div>

            <div class="relative bg-white rounded-lg py-6 px-4 shadow-md mb-3 md:px-20 md:py-8">
                <h1 class="headline headline-xxl">Hallo {{ user.concatenated_names }},</h1>

                <p class="body body-m mb-0">
                    Auf dem Dashboard hast du einen übersichtlichen Überblick über alle deine erstellten Karten bzw.
                    Einladungen und kannst diese bequem verwalten.
                </p>
            </div>

            <div v-for="(card, index) in cards" :key="card.id"
                 class="bg-white rounded-lg py-6 px-4 shadow-md mb-3 md:px-20 md:py-8">
                <h2 class="headline headline-xxl">{{ card.name }}</h2>

                <div class="flex flex-wrap items-center mb-6 gap-2">
                    <div class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0"
                         v-if="card.is_an_invitation_card">
                        Einladungskarte
                    </div>

                    <div class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0"
                         v-if="card.type === 'saveTheDate'">
                        Save the Date
                    </div>

                    <div class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0"
                         v-if="card.type === 'thankYou'">
                        Danksagung
                    </div>

                    <div class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0"
                         v-if="card.is_an_invitation_card">
                        <template v-if="card.is_general_invitation">
                            Allgemein
                        </template>

                        <template v-if="card.is_personalized_invitation">
                            Personalisiert
                        </template>
                    </div>

                    <div class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0">
                        {{ __('language.' + card.language) }}
                    </div>

                    <template v-for="schedule in card.wedding_schedules" :key="schedule.id">
                        <div v-if="schedule.type !== 'save_the_date'"
                             class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0">
                            {{ __('invitation.wedding_schedule.' + schedule.type) }}
                        </div>
                    </template>

                    <template v-if="card.has_feedback_yet && card.is_an_invitation_card">

                        <div v-if="card.count_accept_responses > 0"
                             class="inline-block headline headline-m p-2 rounded-lg text-[#116228] bg-[#c7f5d2] mb-0">
                            {{ card.count_accept_responses }} Zusage{{ card.count_accept_responses > 1 ? 'n' : '' }}
                        </div>

                        <div
                            class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0">
                            {{ card.count_responses }}/{{ card.count_responses_total }} Rückmeldungen
                        </div>
                    </template>

                    <div
                        class="inline-block headline headline-m text-flower-700 bg-flower-100 p-2 rounded-lg mb-0"
                        v-if="card.views > 0">
                        {{ __choice('dashboard.card.views', {}, card.views) }}
                    </div>
                </div>

                <span class="block w-full h-[2px] bg-light-gray"></span>

                <nav class="my-6">
                    <ul class="m-0 p-0">
                        <li class="headline headline-m text-flower-900 list-none mb-4">
                            <Link :href="route('app.design_invitation.preview', {
                card: card.slug
              })" class="inline-flex items-center">
                                Gestalten
                                <img class="ml-3" src="../../../images/icons/arrow-right-circle-raven-500.svg"/>
                            </Link>
                        </li>

                        <li v-if="card.is_personalized_invitation && card.is_an_invitation_card"
                            class="headline headline-m text-flower-900 list-none mb-4">
                            <Link :href="route('app.guest_list', { card: card.slug })"
                                  class="inline-flex items-center">
                                Gäste verwalten
                                <img class="ml-3" src="../../../images/icons/arrow-right-circle-raven-500.svg"/>
                            </Link>
                        </li>

                        <li class="headline headline-m text-flower-900 list-none mb-4">
                            <Link :href="route('app.shipping', { card: card.slug })"
                                  class="inline-flex items-center">
                                Versand
                                <img class="ml-3" src="../../../images/icons/arrow-right-circle-raven-500.svg"/>
                            </Link>
                        </li>

                        <li class="headline headline-m text-flower-900 list-none mb-4">
                            <Link :href="route('app.feedback', { card : card.slug })"
                                  class="inline-flex items-center" v-if="card.is_an_invitation_card">
                                Rückmeldungen
                                <img class="ml-3" src="../../../images/icons/arrow-right-circle-raven-500.svg"/>
                            </Link>
                        </li>

                        <li class="headline headline-m text-flower-900 list-none">
                            <Link
                                :href="route('app.design_invitation.edit', { card : card.slug, 'backToRoute': route('app.dashboard') })"
                                class="inline-flex items-center">
                                Einstellungen
                                <img class="ml-3" src="../../../images/icons/arrow-right-circle-raven-500.svg"/>
                            </Link>
                        </li>
                    </ul>
                </nav>

                <span class="block w-full h-[2px] bg-light-gray"></span>

                <div class="flex items-center justify-between gap-2 mt-5">
                    <LinkComponent :href="route('app.design_invitation.preview', {
                           card: card.slug
                         })"
                                   class="button button-primary w-full">
                        Karte gestalten
                    </LinkComponent>

                    <div class="dot-menu">
                        <div
                            class="flex justify-center items-center w-[50px] h-[42px] border-2 border-flower-300 rounded-lg">
                            <img src="../../../images/icons/icon-more-horizontal.svg"/>
                        </div>

                        <div class="dot-menu--flyout">
                            <ConfirmationDialog ref="deleteDialogRefs">
                                <template v-slot:title>
                                    Karte löschen?
                                </template>

                                <template v-slot:button>
                                    <form
                                        class="w-full"
                                        @submit.prevent="form.post(route('app.dashboard.delete_card', {
                      card: card.slug
                    }), {
                        preserveState: false
                    })">
                                        <ButtonComponent :processing="form.processing"
                                                         class="button button-primary w-full h-full"
                                                         type="submit">
                                            Bestätigen
                                        </ButtonComponent>
                                    </form>
                                </template>
                            </ConfirmationDialog>

                            <ConfirmationDialog ref="duplicateDialogRefs">
                                <template v-slot:title>
                                    Karte duplizieren?
                                </template>

                                <template v-slot:description>
                                    Bitte beachtet, dass eine exakte Kopie der Karte erstellt wird. Ihr könnt das
                                    Duplikat später bearbeiten oder löschen.
                                </template>

                                <template v-slot:button>
                                    <form
                                        class="w-full"
                                        @submit.prevent="form.post(route('app.dashboard.duplicate_card', {
                  card: card.slug
                }), {
                    preserveState: false
                })">
                                        <ButtonComponent :processing="form.processing"
                                                         class="button button-primary w-full h-full"
                                                         type="submit">
                                            Bestätigen
                                        </ButtonComponent>
                                    </form>
                                </template>
                            </ConfirmationDialog>

                            <ul class="min-w-[170px] m-0 mt-[10px] p-4 py-2 bg-white rounded-lg shadow-md">
                                <li class="headline headline-s list-none mb-4">
                              <span class="block" @click="openDuplicateModal(index)">
                                Duplizieren
                              </span>
                                </li>

                                <li class="headline headline-s list-none">
                              <span class="block" @click="openDeleteModal(index)">
                                Löschen
                              </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="cards.length === 0" class="bg-white rounded-lg py-6 px-4 shadow-md mb-3 md:px-20 md:py-8">
                <h3 class="headline headline-m text-[#6f7f9f] text-center mb-6">
                    Bisher ist keine Karte vorhanden.
                </h3>

                <div class="mx-auto text-center">
                    <LinkComponent :href="route('app.dashboard.card.choose_card_type')"
                                   class="button button-primary w-auto">
                        <img class="mr-3" src="../../../images/icons/icon-plus-white.svg"/>
                        Neue Karte erstellen
                    </LinkComponent>
                </div>
            </div>

            <div v-if="cards.length > 0" class="bg-white rounded-lg py-6 px-4 shadow-md mb-3 md:px-20 md:py-8">
                <h3 class="headline headline-m text-flower-700 text-center mb-6">
                    Nicht alle Gäste sollen die gleiche Einladung erhalten?
                </h3>

                <div class="mx-auto text-center">
                    <LinkComponent :href="route('app.dashboard.card.choose_card_type')"
                                   class="button button-secondary w-auto">
                        <img class="mr-3" src="../../../images/icons/icon-plus.svg"/>
                        Neue Karte erstellen
                    </LinkComponent>
                </div>
            </div>
        </div>
    </layout>
</template>

<style lang="css">
.dot-menu {
    @apply cursor-pointer relative;

    &:hover {
        .dot-menu--flyout {
            display: block !important;
        }
    }

    .dot-menu--flyout {
        @apply hidden absolute right-0;
    }
}
</style>
