<script setup lang="ts">
import {computed, PropType, ref} from 'vue';
import {Link} from '@inertiajs/vue3';
import {useMixin} from "@/mixins";
import Footer from "@/Designs/Components/Footer.vue";
import Salutation from "@/Designs/Components/Salutation.vue";

const {
    route,
    getTime,
    getDate,
    generateRoute,
    __,
    getSalutation,
    getImagesFromCallery,
    getColorByThemeAndOption,
    isSameAppVersion,
    isMinimumAppVersion,
    getAddress,
    getDirections,
} = useMixin();

const loading = ref(false);

const loadingStart = () => {
    loading.value = true;

    setTimeout(() => {
        loading.value = false;
    }, 2000);
};

const props = defineProps({
    card: {
        type: Object as PropType<App.Models.Card>,
        required: true,
    },
    state: {
        type: String,
        required: true,
    },
    invitation: {
        type: Object as PropType<App.Models.Invitation>,
    }
});

const background = computed(() => {
    const theme = props.card.theme;
    return new URL(`../../../images/designs/elegant/bg-texture_${theme}.jpg`, import.meta.url).href;
});

const hexagon = computed(() => {
    const theme = props.card.theme;
    return new URL(`../../../images/designs/elegant/hexagon_${theme}.svg`, import.meta.url).href;
});

const elementFloral = computed(() => {
    const theme = props.card.theme;
    return new URL(`../../../images/designs/elegant/element-floral_${theme}.svg`, import.meta.url).href;
});

const polygon = computed(() => {
    const theme = props.card.theme;
    return new URL(`../../../images/designs/elegant/polygon_${theme}.svg`, import.meta.url).href;
});
</script>

<template>
    <div>
        <template v-if="state === 'invitation'">
            <div class="relative w-full min-h-screen p-4"
                 :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')};`">
                <div class="hidden md:inline-block absolute inset-0"
                     :style="`background-image: url(${background});`"></div>
                <img :src="hexagon" class="hidden md:block absolute left-0 right-0 top-0 mx-auto z-10"/>
                <div class="min-h-screen relative z-20 border md:w-[700px] md:mx-auto md:mt-[68px]"
                     :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')}; border-image: linear-gradient(${getColorByThemeAndOption(card.theme, 'frame')} 15%, ${getColorByThemeAndOption(card.theme, 'background')} 25%) 10;`">
                    <div
                        class="mt-[104px] w-full px-4 min-h-[200px] flex justify-center items-center md:w-[520px] md:mx-auto md:px-[30px]">
                        <div class="w-full flex flex-row justify-between font-aboreto text-[90px] md:text-[132px]"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'bgTypo')};`">
                            <span>L</span>
                            <span>O</span>
                            <span>V</span>
                            <span>E</span>
                        </div>

                        <div class="absolute flex flex-col justify-center text-center font-brittany"
                             :class="{'text-[42px]' : card.user?.name1?.length < 10 && card.user?.name2?.length < 10, 'text-[28px]' : (card.user?.name1?.length > 10 && card.user?.name1?.length < 22) || (card.user?.name2?.length > 10 && card.user?.name2?.length < 22), 'text-[24px]' : card.user?.name1?.length > 22 || card.user?.name2?.length > 22}"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`">
                            <span>{{ card.user.name1 }}</span>
                            <span>&</span>
                            <span>{{ card.user.name2 }}</span>
                        </div>
                    </div>

                    <template v-if="card.user.id !== 7591 && card.user.id !== 7930">
                        <div
                            class="mt-[120px] flex gap-3 justify-center items-center font-aboreto leading-4 text-[36px] md:w-[520px] md:mx-auto"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                            <span>{{ getDate(card.first_wedding_schedule.scheduled_at, 'DD') }}</span>
                            <span class="w-[2px] h-8"
                                  :style="`background-color: ${getColorByThemeAndOption(card.theme, 'dividerBorder')};`"></span>
                            <span>{{ getDate(card.first_wedding_schedule.scheduled_at, 'MM') }}</span>
                            <span class="w-[2px] h-8"
                                  :style="`background-color: ${getColorByThemeAndOption(card.theme, 'dividerBorder')};`"></span>
                            <span>{{ getDate(card.first_wedding_schedule.scheduled_at, 'YY') }}</span>
                        </div>
                    </template>

                    <template v-if="card.user.id === 7591 || card.user.id === 7930">
                        <div class="mt-[120px]"></div>
                    </template>

                    <template v-if="!card.has_photo_block && card.user.id !== 7591">
                        <div
                            class="w-[248px] m-auto relative flex justify-center mt-8 mb-[72px] font-aboreto text-[70px]"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'devorativeSmall')};`">
                            <img :src="polygon" alt="Polygon" class="w-[248px]"/>
                            <div class="absolute left-[60px] top-[60px]"
                                 :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                                {{ card.user.name1[0] }}
                            </div>
                            <div class="absolute right-[75px] bottom-[60px]"
                                 :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                                {{ card.user.name2[0] }}
                            </div>
                        </div>
                    </template>

                    <template v-if="card.has_photo_block">
                        <div class="mx-auto">
                            <img class="mx-auto mt-8 mb-[72px]"
                                 :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"
                                 alt="Photo"/>
                        </div>
                    </template>

                    <div class="relative flex flex-wrap justify-center px-4 md:w-[465px] md:mx-auto">
                        <div class="w-full flex flex-row justify-between font-aboreto text-[64px]"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'bgTypo')};`">

                            <template v-if="card.is_personalized_invitation && invitation">
                                <template v-if="invitation?.guests?.length === 1">
                                    <span
                                        v-for="(letter, index) in getSalutation(invitation)"
                                        :key="index">{{ letter }}</span>
                                </template>

                                <template v-if="invitation?.guests?.length > 1">
                                    <span v-for="(letter, index) in getSalutation(invitation)" :key="index">
                                        {{ letter }}
                                    </span>
                                </template>
                            </template>

                            <template v-if="card.is_general_invitation">
                                    <span v-for="(letter, index) in __('invitation.salutation.plural')"
                                          :key="index">{{ letter }}</span>
                            </template>
                        </div>

                        <div class="font-brittany text-center -mt-14"
                             :class="{'text-[50px]': card.salutation.salutation === 'guests', 'text-[40px]': card.salutation.salutation !== 'guests'}"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`"
                             v-if="card.is_general_invitation">
                            <Salutation :salutation="card.salutation"/>
                        </div>

                        <div
                            :class="{'font-brittany text-center -mt-10 leading-[80px]': true, 'text-[50px]': Object.keys(invitation?.guests_without_additional_guests).length <= 2, 'text-[40px]': Object.keys(invitation?.guests_without_additional_guests).length > 2}"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`"
                            v-if="card.is_personalized_invitation">
                            {{ invitation.guests_names_for_salutation }}
                        </div>
                    </div>

                    <div
                        class="mt-16 w-[343px] text-center mx-auto font-montserrat text-[14px] uppercase leading-[160%] tracking-[4px]">
                        <div :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                             v-html="card.foreword"/>

                        <div class="my-[40px] font-aboreto text-[48px] leading-[48px] tracking-normal"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'date')};`">
                            {{ getDate(card.first_wedding_schedule.scheduled_at, 'DD.MM.YYYY') }}
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-center gap-6">
                        <template v-for="(schedule, index) in card.wedding_schedules">
                            <div class="font-aboreto text-[48px] tracking-normal"
                                 v-if="index > 0 && getDate(schedule.scheduled_at as Date, 'DD.MM.YYYY') !== getDate(card.wedding_schedules[index - 1].scheduled_at as Date, 'DD.MM.YYYY')"
                                 :style="`color: ${getColorByThemeAndOption(card.theme, 'date')};`">
                                {{ getDate(schedule.scheduled_at, 'DD.MM.YYYY') }}
                            </div>

                            <div
                                class="w-[343px] py-[40px] text-center border flex flex-col items-center justify-center md:w-[520px]"
                                :style="`border-color: ${getColorByThemeAndOption(card.theme, 'dividerBorder')};`">
                                <div class="font-aboreto text-[24px] mb-3 tracking-[11%]"
                                     :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                                    {{
                                        card.user_id === 6707 ? 'Trauungsfeier' : __('invitation.wedding_schedule.' + schedule.type)
                                    }}
                                </div>
                                <div class="font-montserrat text-[14px] mb-3 tracking-[4px]"
                                     :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                                    {{ getTime(schedule.scheduled_at as Date, card) }}
                                </div>
                                <div class="font-montserrat text-[14px] tracking-[1px] px-4"
                                     :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                                     v-html="schedule.location">
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="mt-[72px] flex justify-center flex-wrap flex-col text-center md:w-[340px] md:mx-auto">
                        <div class="font-brittany text-[32px] mb-8"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                            {{ __('invitation.informations') }}
                        </div>

                        <template v-for="(block, key) in card.content_blocks">
                            <div class="w-[340px] mx-auto font-montserrat text-[12px] leading-[160%] tracking-[1px]"
                                 :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                                <div class="font-aboreto text-[20px] mb-6 tracking-[11%]"
                                     v-if="block.type !== 'invitationText'">
                                    <template v-if="block.type === 'agenda'">
                                        {{ __('invitation.agenda') }}
                                    </template>

                                    <template v-if="block.type === 'weddingGames'">
                                        {{ __('invitation.wedding_games') }}
                                    </template>

                                    <template v-if="block.type === 'groomsmen'">
                                        {{ __('invitation.groomsmen') }}
                                    </template>

                                    <template v-if="block.type === 'giftWishes'">
                                        {{ __('invitation.gift_wishes') }}
                                    </template>

                                    <template v-if="block.type === 'dresscode'">
                                        {{ __('invitation.dresscode') }}
                                    </template>

                                    <template v-if="block.type === 'kids'">
                                        {{ __('invitation.kids') }}
                                    </template>

                                    <template v-if="block.type === 'accommodation'">
                                        {{ __('invitation.accommodation') }}
                                    </template>

                                    <template v-if="block.type === 'freeText'">
                                        {{ block.data['header'] }}
                                    </template>
                                </div>

                                <span v-html="block.data.text" class="text-[14px]" v-if="block.type !== 'dresscode'"/>

                                <template v-if="block.type === 'dresscode'">
                                    <span v-if="block.data['dresscode'] === 'white_tie'" class="text-[14px]">
                                        {{ __('invitation.dresscode.white_tie') }}
                                    </span>

                                    <span v-if="block.data['dresscode'] === 'black_tie'" class="text-[14px]">
                                        {{ __('invitation.dresscode.black_tie') }}
                                    </span>

                                    <span v-if="block.data['dresscode'] === 'cocktail'" class="text-[14px]">
                                        {{ __('invitation.dresscode.cocktail') }}
                                    </span>

                                    <span v-if="block.data['dresscode'] === 'casual'" class="text-[14px]">
                                        {{ __('invitation.dresscode.casual') }}
                                    </span>

                                    <span v-if="block.data['dresscode'] === 'custom'" v-html="block.data['text']"
                                          class="text-[14px]"/>
                                </template>
                            </div>

                            <template v-if="key < Object.keys(card.content_blocks).length">
                                <img :src="elementFloral" alt="Floral Element" class="my-8"/>
                            </template>
                        </template>
                    </div>

                    <div
                        class="my-[72px] flex flex-wrap flex-col justify-center text-center py-8 px-4 md:w-[520px] md:mx-auto"
                        :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                        v-if="isSameAppVersion(card, 1) || (isMinimumAppVersion(card, 2) && card.blocks.filter(x => x.type === 'arrival')[0])">
                        <div class="font-brittany text-[32px] leading-[56px] mb-6 md:w-[340px] md:mx-auto"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                            {{ __('invitation.journey') }}
                        </div>

                        <div
                            class="max-w-[200px] mx-auto font-montserrat text-[14px] leading-[160%] tracking-[1px] md:w-[340px] md:mx-auto"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                            {{ getAddress(card) }}
                        </div>

                        <a :href="'http://maps.google.de/maps?f=q&hl=de&q=' + encodeURIComponent(getAddress(card))"
                           target="_blank"
                           :class="`w-[263px] mx-auto mt-6 font-montserrat text-[${getColorByThemeAndOption(card.theme, 'button')}] text-[14px] leading-[20px] py-[11px] font-semibold rounded-[6px] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] hover:text-[${getColorByThemeAndOption(card.theme, 'buttonTypo')}]`"
                           :style="`color: ${getColorByThemeAndOption(card.theme, 'button')};`">
                            {{ __('invitation.open_in_google_maps') }}
                        </a>

                        <div
                            class="mt-[40px] font-montserrat text-[14px] leading-[160%] tracking-[1px] md:w-[340px] md:mx-auto"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                            v-if="getDirections(card)"
                            v-html="getDirections(card)">
                        </div>
                    </div>

                    <div
                        class="my-[72px] flex flex-wrap flex-col justify-center text-center border py-8 px-4 md:w-[520px] md:mx-auto"
                        :style="`color: ${getColorByThemeAndOption(card.theme, 'text')}; border-color: ${getColorByThemeAndOption(card.theme, 'dividerBorder')};`">
                        <div class="font-brittany text-[32px] leading-[56px] mb-6 md:w-[340px] md:mx-auto"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                            <template v-if="!(invitation?.was_answered_already)">
                                {{ __('invitation.feedback.headline') }}
                            </template>

                            <template v-if="invitation?.was_answered_already">
                                {{ __('invitation.feedback.thanks') }}
                            </template>
                        </div>

                        <div
                            class="font-montserrat text-[14px] leading-[160%] tracking-[1px] md:w-[340px] md:mx-auto"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`">
                            <template v-if="!card.feedback_text">
                                {{ __('invitation.feedback.text_default') }}
                            </template>
                            <span v-html="card.feedback_text"/>
                        </div>

                        <template v-if="card.is_personalized_invitation">
                            <Link
                                :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation': invitation.slug })"
                                :class="`flex justify-center items-center w-[263px] mx-auto mt-6 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold border-2 rounded-lg border-[${getColorByThemeAndOption(card.theme, 'button')}] text-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] hover:text-[#ffffff] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="invitation?.was_answered_already"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.edit_feedback') }}
                                </div>
                            </Link>

                            <Link
                                :href="generateRoute('app.invitation.feedback.personal.chooser', invitation, { 'card': card, 'invitation' : invitation.slug })"
                                :class="`flex justify-center items-center w-[263px] mx-auto mt-6 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold rounded-[6px] bg-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="!invitation?.was_answered_already"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                     :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.submit_feedback_now') }}
                                </div>
                            </Link>

                            <a
                                :href="route('app.invitation.ical', { 'card': card })"
                                :class="`w-[263px] mx-auto mt-4 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold rounded-lg bg-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="invitation?.was_answered_already">
                                {{ __('invitation.add_to_calendar') }}
                            </a>
                        </template>

                        <template v-if="card.is_general_invitation">
                            <Link
                                :href="generateRoute('app.invitation.feedback.general.edit', invitation, { 'card': card })"
                                :class="`flex justify-center items-center w-[263px] mx-auto mt-6 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold border-2 rounded-lg border-[${getColorByThemeAndOption(card.theme, 'button')}] text-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] hover:text-[#ffffff] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="invitation?.was_answered_already"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.edit_feedback') }}
                                </div>
                            </Link>

                            <Link
                                :href="generateRoute('app.invitation.feedback.general.chooser', invitation, { 'card' : card })"
                                :class="`flex justify-center items-center w-[263px] mx-auto mt-6 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold bg-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="!invitation?.was_answered_already"
                                preserve-state preserve-scroll
                                @click="loadingStart">
                                <svg v-show="loading"
                                     class="animate-spin h-3 w-3 absolute left-auto right-auto "
                                     fill="none"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          fill="currentColor"></path>
                                </svg>

                                <div :class="{'invisible' : loading}" class="inline-flex">
                                    {{ __('invitation.feedback.submit_feedback_now') }}
                                </div>
                            </Link>

                            <a
                                :href="route('app.invitation.ical', { 'card': card })"
                                :class="`w-[263px] mx-auto mt-4 font-montserrat text-[14px] leading-[20px] py-[11px] font-semibold rounded-lg bg-[${getColorByThemeAndOption(card.theme, 'button')}] hover:bg-[${getColorByThemeAndOption(card.theme, 'buttonHover')}] border-2 border-[${getColorByThemeAndOption(card.theme, 'button')}] hover:border-[${getColorByThemeAndOption(card.theme, 'buttonHover')}]`"
                                :style="`color: ${getColorByThemeAndOption(card.theme, 'buttonTypo')};`"
                                v-if="invitation?.was_answered_already">
                                {{ __('invitation.add_to_calendar') }}
                            </a>
                        </template>
                    </div>

                    <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                            class="md:hidden my-[40px]"/>
                </div>

                <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                        class="relative hidden md:block my-[40px]"/>
            </div>
        </template>

        <template v-if="state === 'saveTheDate'">
            <div class="relative w-full min-h-screen p-4"
                 :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')};`">
                <div class="hidden md:inline-block absolute inset-0"
                     :style="`background-image: url(${background});`"></div>
                <img :src="hexagon" class="hidden md:block absolute left-0 right-0 top-0 mx-auto bottom-0 z-10 "/>
                <div class="min-h-screen relative z-20 border md:w-[700px] md:mx-auto md:mt-[68px] md:min-h-0"
                     :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')}; border-image: linear-gradient(${getColorByThemeAndOption(card.theme, 'frame')} 60%, ${getColorByThemeAndOption(card.theme, 'background')} 100%) 10;`">
                    <div
                        class="relative mt-[80px] w-full px-4 min-h-[200px] md:w-[350px] md:mx-auto md:px-[30px]">
                        <div class="w-full flex flex-row justify-between font-aboreto text-[90px] md:text-[90px]"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'bgTypo')};`">
                            <span>S</span>
                            <span>A</span>
                            <span>V</span>
                            <span>E</span>
                        </div>

                        <div
                            class="absolute left-4 right-0 bottom-20 mx-auto text-center font-montserrat text-[14px] uppercase">
                            the
                        </div>

                        <div class="mx-auto text-center font-brittany text-[48px]"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`">
                            Date
                        </div>
                    </div>

                    <div
                        class="mt-[56px] w-[343px] text-center mx-auto font-montserrat text-[14px] uppercase leading-[160%] tracking-[4px]">
                        <div :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                             v-html="card.foreword"/>

                        <div class="mt-6 font-aboreto text-[48px] leading-[48px] tracking-normal"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'date')};`">
                            {{ getDate(card.first_wedding_schedule.scheduled_at, 'DD.MM.YYYY') }}
                        </div>
                    </div>

                    <template v-if="card.has_photo_block">
                        <img class="mx-auto mt-[56px]"
                             :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"
                             alt="Photo"/>
                    </template>

                    <div
                        class="mt-[56px] mb-[80px] flex justify-center text-center font-brittany text-[22px] md:text-[24px]"
                        :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`">
                        <span>{{ card.user.name1 }}</span>
                        <span>&nbsp;&amp;&nbsp;</span>
                        <span>{{ card.user.name2 }}</span>
                    </div>

                    <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                            class="md:hidden my-[40px]"/>
                </div>
                <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                        class="relative hidden md:block mt-[80px] mb-[40px]"/>
            </div>
        </template>

        <template v-if="state === 'thankYou'">
            <div class="relative w-full min-h-screen p-4"
                 :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')};`">
                <div class="hidden md:inline-block absolute inset-0"
                     :style="`background-image: url(${background});`"></div>
                <img :src="hexagon" class="hidden md:block absolute left-0 right-0 top-0 mx-auto bottom-0 z-10 "/>
                <div
                    class="min-h-screen relative z-20 border md:w-[700px] md:mx-auto md:mt-[68px] md:min-h-0 md:pb-[80px]"
                    :style="`background-color: ${getColorByThemeAndOption(card.theme, 'background')}; border-image: linear-gradient(${getColorByThemeAndOption(card.theme, 'frame')} 60%, ${getColorByThemeAndOption(card.theme, 'background')} 100%) 10;`">
                    <div
                        class="mt-[130px] w-full px-4 flex flex-col justify-center items-center md:w-[520px] md:mx-auto md:px-[30px]">
                        <div
                            class="flex flex-col justify-center text-center font-brittany text-[42px] -mb-4 md:text-[48px] md:-mb-12 z-50"
                            :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`">
                            <span>{{ __('thankYou.we_say') }}</span>
                        </div>

                        <div class="w-full flex flex-row justify-between font-aboreto"
                             :class="{'text-[64px] md:text-[90px]': __('thankYou.thanks').length <= 8, 'text-[35px] md:text-[52px]': __('thankYou.thanks').length > 8}"
                             :style="`color: ${getColorByThemeAndOption(card.theme, 'bgTypo')};`">
                               <span
                                   v-for="(letter, index) in __('thankYou.thanks')"
                                   :key="index">{{ letter }}</span>
                        </div>
                    </div>

                    <div
                        class="mt-[84px] w-[343px] text-center mx-auto font-montserrat text-[14px] uppercase leading-[160%] tracking-[4px]">
                        <div :style="`color: ${getColorByThemeAndOption(card.theme, 'text')};`"
                             v-html="card.foreword"/>
                    </div>

                    <template v-if="card.has_photo_block">
                        <img class="mx-auto mt-[56px]"
                             :src="card.blocks.filter(x => x.type === 'photo')[0].data.url"
                             alt="Photo"/>
                    </template>

                    <div
                        class="mt-[56px] mb-[80px] flex justify-center text-center font-brittany text-[22px] md:text-[24px]"
                        :style="`color: ${getColorByThemeAndOption(card.theme, 'decorative')};`">
                        <span>{{ card.user.name1 }}</span>
                        <span>&nbsp;&amp;&nbsp;</span>
                        <span>{{ card.user.name2 }}</span>
                    </div>

                    <div class="px-4 md:w-[520px] md:mx-auto" v-if="card.has_gallery_block">
                        <div>
                            <img :src="elementFloral" alt="Floral Element" class="block mx-auto"/>
                        </div>
                        <div
                            class="mt-[40px] flex justify-center flex-wrap flex-col text-center md:w-[340px] md:mx-auto">
                            <div class="font-aboreto text-[18px] mb-[56px] md:mb-[32px]"
                                 :style="`color: ${getColorByThemeAndOption(card.theme, 'decorativeSmall')};`">
                                {{ __('thankYou.gallery.headline') }}
                            </div>
                        </div>

                        <div class="grid grid-cols-1 gap-4">
                            <div v-for="image in getImagesFromCallery(card)">
                                <img class="object-cover object-center w-full max-w-full"
                                     :src="image"/>
                            </div>
                        </div>
                    </div>

                    <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                            class="md:hidden mt-[73px] my-[40px]"/>
                </div>
                <Footer :card="card" :color="getColorByThemeAndOption(card.theme, 'footer')"
                        class="relative hidden md:block mt-[80px] mb-[40px]"/>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Aboreto&family=Montserrat:wght@300;400;600;700&display=swap');
</style>
